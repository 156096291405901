import { Icon } from "@components/shared/Icon";
import classNames from "classnames";
import * as React from "react";

interface ExitElementProps {
  isRight: boolean;
}

export const ExitElement: React.FC<ExitElementProps> = ({ isRight }) => (
  <div
    className={classNames("map-element map-element--exit", {
      "map-element--exit--right": isRight,
    })}
  >
    {isRight ? <Icon name="exit_row_right" /> : <Icon name="exit_row" />}
  </div>
);
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";