import {
  OfferAvailableService,
  OfferAvailableServiceCFAR,
} from "@duffel/api/types";

export const isCancelForAnyReasonService = (
  service: OfferAvailableService,
): service is OfferAvailableServiceCFAR =>
  service.type === "cancel_for_any_reason";
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";