import { SeatMap, SeatMapCabinRowSectionElementSeat } from "@duffel/api/types";
import { getFirstSeatElementMatchingCriteria } from "./getFirstSeatElementMatchingCriteria";

/**
 *
 * Returns the currency of the first service of the given seat maps.
 *
 * @param seatMaps[] Array of seat maps
 * @returns A string representing the currency of the seat maps in the offer.
 */
const getCurrencyForSeatMaps = (seatMaps: SeatMap[]): string | undefined => {
  const firstElementWithServices:
    | SeatMapCabinRowSectionElementSeat
    | undefined = getFirstSeatElementMatchingCriteria(
    seatMaps,
    (element) =>
      element.type === "seat" && element.available_services.length > 0,
  ) as SeatMapCabinRowSectionElementSeat;
  return firstElementWithServices?.available_services[0]?.total_currency;
};

export { getCurrencyForSeatMaps };
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";