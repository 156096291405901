import { CreateOrder } from "@duffel/api/types";

export const isPayloadComplete = (
  payload: Partial<CreateOrder>,
): payload is CreateOrder =>
  "selected_offers" in payload &&
  "passengers" in payload &&
  "services" in payload &&
  "payments" in payload &&
  "type" in payload &&
  "metadata" in payload;
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";