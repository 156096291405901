import {
  SeatMapCabinRow,
  SeatMapCabinRowSectionElementSeat,
} from "@duffel/api/types";

export const getRowNumber = (row: SeatMapCabinRow): string | null => {
  const seats = Object.values(row.sections)
    .map((section) => section.elements)
    .reduce((acc, val) => acc.concat(val), [])
    .filter(
      (element) => element.type === "seat",
    ) as SeatMapCabinRowSectionElementSeat[];

  return seats.length > 0
    ? seats[0].designator.substring(0, seats[0].designator.length - 1)
    : null;
};
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";