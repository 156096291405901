import { Icon } from "@components/shared/Icon";
import React, { ReactNode } from "react";

export const CfarSelectionModalBodyListItem: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <li className="cfar-modal-list-item">
    <Icon name="check" />
    <p>{children}</p>
  </li>
);
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";