import {
  SeatMapCabinRowSectionElement,
  SeatMapCabinRowSectionElementSeat,
} from "@duffel/api/types";

export function isSeatElement(
  element: SeatMapCabinRowSectionElement,
): element is SeatMapCabinRowSectionElementSeat {
  return element.type === "seat";
}
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";