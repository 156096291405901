import { CreateOrder, CreateOrderPassenger } from "@duffel/api/types";

export type PassengerMapById = Record<
  CreateOrderPassenger["id"],
  CreateOrderPassenger
>;

export const getPassengerMapById = (
  passengers: CreateOrder["passengers"],
): PassengerMapById =>
  passengers.reduce(
    (all, passenger) => ({ [passenger.id]: passenger, ...all }),
    {} as PassengerMapById,
  );
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";