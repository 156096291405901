import { SeatMap, SeatMapCabinRowSectionElement } from "@duffel/api/types";

const getFirstSeatElementMatchingCriteria = (
  seatMaps: SeatMap[],
  matcher: (element: SeatMapCabinRowSectionElement) => boolean,
) => {
  for (const seatMap of seatMaps) {
    for (const cabin of seatMap.cabins) {
      for (const row of cabin.rows) {
        for (const section of row.sections) {
          for (const element of section.elements) {
            if (matcher(element)) {
              return element;
            }
          }
        }
      }
    }
  }
};

export { getFirstSeatElementMatchingCriteria };
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";