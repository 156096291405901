import { fetchFromDuffelAPI } from "./fetchFromDuffelAPI";

export async function retrieveOfferFromDuffelAPI(
  offer_id: string,
  client_key: string,
) {
  const getOfferResponse = await fetchFromDuffelAPI(
    client_key,
    `offers/${offer_id}?return_available_services=true`,
  );

  return getOfferResponse.data;
}
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";