import {
  SeatMapCabin,
  SeatMapCabinRowSectionElementAmenity,
} from "@duffel/api/types";

export const getSymbols = (
  cabins: SeatMapCabin[],
): Set<SeatMapCabinRowSectionElementAmenity> => {
  const results: Set<SeatMapCabinRowSectionElementAmenity> = new Set();
  for (const cabin of cabins) {
    for (const row of cabin.rows) {
      for (const section of row.sections) {
        for (const element of section.elements) {
          if (element.type !== "seat" && element.type !== "empty") {
            results.add(element.type);
          }
        }
      }
    }
  }
  return results;
};
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";