import { CreateOrder } from "@duffel/api/types";

export const getTotalQuantity = (
  fromSelectedServices: CreateOrder["services"],
) =>
  (fromSelectedServices || []).reduce(
    (total, { quantity }) => total + quantity,
    0,
  );
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";