import { OfferAvailableService } from "@duffel/api/types";

export type ServicePriceMapById = Record<
  OfferAvailableService["id"],
  Pick<OfferAvailableService, "total_amount" | "total_currency">
>;

export const getServicePriceMapById = (
  availableServices: OfferAvailableService[],
): ServicePriceMapById =>
  availableServices.reduce(
    (all, { id, total_amount, total_currency }) => ({
      [id]: {
        total_amount,
        total_currency,
      },
      ...all,
    }),
    {} as ServicePriceMapById,
  );
;
import "/Users/igordepaula/.yarn/berry/cache/@sentry-bundler-plugin-core-npm-0.7.2-054e3436fd-10c0.zip/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";